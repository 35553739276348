// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
  aws_project_region: "us-east-2",
  aws_cognito_identity_pool_id:
    "us-east-2:96842b11-f648-4de4-b993-2681731c88e7",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_ltTZMOe0x",
  aws_user_pools_web_client_id: "4nv8o67lk3os6ar9qi6ag6gb7o",
  oauth: {},
  aws_cloud_logic_custom: [
    {
      name: "AdminQueries",
      endpoint: "https://z3avjeymjb.execute-api.us-east-2.amazonaws.com/dev",
      region: "us-east-2"
    }
  ]
};
export default awsmobile;
